import React from 'react';
import Sidebar from '../components/Sidebar';

const Dashboard = () => {
    return (
        <div>
            <Sidebar>
            <h1>Welcome Admin!</h1>
            </Sidebar>
        </div>
    );
};

export default Dashboard;